export var colorAmazonOrange = "var(--color-amazon-orange-gsvdv7, #ff9900)";
export var colorAwsSquidInk = "var(--color-aws-squid-ink-uuesmr, #232f3e)";
export var colorBlack = "var(--color-black-q5so4f, #000000)";
export var colorBlue100 = "var(--color-blue-100-wlcs2n, #f0fbff)";
export var colorBlue200 = "var(--color-blue-200-sd5adl, #d1f1ff)";
export var colorBlue300 = "var(--color-blue-300-5k8cbj, #b8e7ff)";
export var colorBlue400 = "var(--color-blue-400-946rwg, #75cfff)";
export var colorBlue500 = "var(--color-blue-500-5xgaz7, #42b4ff)";
export var colorBlue600 = "var(--color-blue-600-reb9vf, #006ce0)";
export var colorBlue700 = "var(--color-blue-700-v3a2es, #004a9e)";
export var colorBlue800 = "var(--color-blue-800-5d4pjk, #002b66)";
export var colorBlue900 = "var(--color-blue-900-z611ct, #001129)";
export var colorBlueOpaque = "var(--color-blue-opaque-gzkvwf, rgba(51, 136, 221, 0.5))";
export var colorGreen100 = "var(--color-green-100-krlue2, #effff1)";
export var colorGreen500 = "var(--color-green-500-ce8frt, #2bb534)";
export var colorGreen600 = "var(--color-green-600-5989fd, #00802f)";
export var colorGreen700 = "var(--color-green-700-b7c0jd, #00471e)";
export var colorGreen900 = "var(--color-green-900-23ye01, #001401)";
export var colorGrey100 = "var(--color-grey-100-v3ezs2, #f9f9fa)";
export var colorGrey125 = "var(--color-grey-125-eoue5v, #f6f6f9)";
export var colorGrey150 = "var(--color-grey-150-0aamhe, #f3f3f7)";
export var colorGrey200 = "var(--color-grey-200-oj8zih, #ebebf0)";
export var colorGrey300 = "var(--color-grey-300-hqvt1h, #dedee3)";
export var colorGrey350 = "var(--color-grey-350-7dh6jp, #c6c6cd)";
export var colorGrey400 = "var(--color-grey-400-65txhu, #b4b4bb)";
export var colorGrey450 = "var(--color-grey-450-dchvfk, #a4a4ad)";
export var colorGrey500 = "var(--color-grey-500-cbi6nr, #8c8c94)";
export var colorGrey550 = "var(--color-grey-550-sy2mz4, #656871)";
export var colorGrey600 = "var(--color-grey-600-orr5wj, #424650)";
export var colorGrey650 = "var(--color-grey-650-r7rd0u, #333843)";
export var colorGrey700 = "var(--color-grey-700-2nl5fe, #232b37)";
export var colorGrey750 = "var(--color-grey-750-dqp236, #1b232d)";
export var colorGrey800 = "var(--color-grey-800-n0zpkl, #161d26)";
export var colorGrey850 = "var(--color-grey-850-wka0kd, #131920)";
export var colorGrey900 = "var(--color-grey-900-o3bf8c, #0f141a)";
export var colorGrey950 = "var(--color-grey-950-62cv93, #06080a)";
export var colorOrange100 = "var(--color-orange-100-akgi2l, #fef6f0)";
export var colorOrange500 = "var(--color-orange-500-dpl1nc, #ec7211)";
export var colorOrange600 = "var(--color-orange-600-5vsg33, #eb5f07)";
export var colorOrange700 = "var(--color-orange-700-3igdeo, #dd6b10)";
export var colorPurple400 = "var(--color-purple-400-wvnlma, #bf80ff)";
export var colorPurple600 = "var(--color-purple-600-468euy, #7300e5)";
export var colorRed100 = "var(--color-red-100-omy75e, #fff5f5)";
export var colorRed500 = "var(--color-red-500-281f53, #ff7a7a)";
export var colorRed600 = "var(--color-red-600-oguye6, #db0000)";
export var colorRed700 = "var(--color-red-700-vpn9s6, #700000)";
export var colorRed900 = "var(--color-red-900-lsdc77, #1f0000)";
export var colorTransparent = "var(--color-transparent-0ltpn5, transparent)";
export var colorWhite = "var(--color-white-s5xhu1, #ffffff)";
export var colorYellow100 = "var(--color-yellow-100-gqzix7, #fffef0)";
export var colorYellow600 = "var(--color-yellow-600-b1opgq, #ffe347)";
export var colorYellow700 = "var(--color-yellow-700-vw7jd7, #fbd332)";
export var colorYellow800 = "var(--color-yellow-800-0kls3p, #855900)";
export var colorYellow900 = "var(--color-yellow-900-n1l6gi, #191100)";
export var colorChartsRed300 = "var(--color-charts-red-300-l64jkq, #ea7158)";
export var colorChartsRed400 = "var(--color-charts-red-400-wa91b2, #dc5032)";
export var colorChartsRed500 = "var(--color-charts-red-500-clvvu0, #d13313)";
export var colorChartsRed600 = "var(--color-charts-red-600-n5f5z7, #ba2e0f)";
export var colorChartsRed700 = "var(--color-charts-red-700-9qnabz, #a82a0c)";
export var colorChartsRed800 = "var(--color-charts-red-800-03myov, #972709)";
export var colorChartsRed900 = "var(--color-charts-red-900-t7y4st, #892407)";
export var colorChartsRed1000 = "var(--color-charts-red-1000-2569eu, #7d2105)";
export var colorChartsRed1100 = "var(--color-charts-red-1100-utabtk, #721e03)";
export var colorChartsRed1200 = "var(--color-charts-red-1200-p41vo4, #671c00)";
export var colorChartsOrange300 = "var(--color-charts-orange-300-8xdemd, #e07941)";
export var colorChartsOrange400 = "var(--color-charts-orange-400-98kh01, #cc5f21)";
export var colorChartsOrange500 = "var(--color-charts-orange-500-3exfo1, #bc4d01)";
export var colorChartsOrange600 = "var(--color-charts-orange-600-tyzxch, #a84401)";
export var colorChartsOrange700 = "var(--color-charts-orange-700-9es7jg, #983c02)";
export var colorChartsOrange800 = "var(--color-charts-orange-800-zzyr3p, #8a3603)";
export var colorChartsOrange900 = "var(--color-charts-orange-900-ozn3mk, #7e3103)";
export var colorChartsOrange1000 = "var(--color-charts-orange-1000-2j9x6g, #732c02)";
export var colorChartsOrange1100 = "var(--color-charts-orange-1100-gbb7zd, #692801)";
export var colorChartsOrange1200 = "var(--color-charts-orange-1200-k1a2bc, #602400)";
export var colorChartsYellow300 = "var(--color-charts-yellow-300-ud5z8m, #b2911c)";
export var colorChartsYellow400 = "var(--color-charts-yellow-400-er893i, #9c7b0b)";
export var colorChartsYellow500 = "var(--color-charts-yellow-500-zf6yw3, #8a6b05)";
export var colorChartsYellow600 = "var(--color-charts-yellow-600-e1b0zr, #7b5f04)";
export var colorChartsYellow700 = "var(--color-charts-yellow-700-3ihyrv, #6f5504)";
export var colorChartsYellow800 = "var(--color-charts-yellow-800-2luor7, #654d03)";
export var colorChartsYellow900 = "var(--color-charts-yellow-900-k8cds4, #5d4503)";
export var colorChartsYellow1000 = "var(--color-charts-yellow-1000-9ibbxn, #553f03)";
export var colorChartsYellow1100 = "var(--color-charts-yellow-1100-igbqae, #4d3901)";
export var colorChartsYellow1200 = "var(--color-charts-yellow-1200-qg1amh, #483300)";
export var colorChartsGreen300 = "var(--color-charts-green-300-gduyir, #67a353)";
export var colorChartsGreen400 = "var(--color-charts-green-400-jvx3c1, #41902c)";
export var colorChartsGreen500 = "var(--color-charts-green-500-uj364g, #1f8104)";
export var colorChartsGreen600 = "var(--color-charts-green-600-mbov5u, #1a7302)";
export var colorChartsGreen700 = "var(--color-charts-green-700-m7xx1p, #176702)";
export var colorChartsGreen800 = "var(--color-charts-green-800-97d577, #145d02)";
export var colorChartsGreen900 = "var(--color-charts-green-900-79vtkm, #125502)";
export var colorChartsGreen1000 = "var(--color-charts-green-1000-dw0ace, #104d01)";
export var colorChartsGreen1100 = "var(--color-charts-green-1100-fdxgm3, #0f4601)";
export var colorChartsGreen1200 = "var(--color-charts-green-1200-0w78ld, #0d4000)";
export var colorChartsTeal300 = "var(--color-charts-teal-300-b1amlp, #2ea597)";
export var colorChartsTeal400 = "var(--color-charts-teal-400-75fmn4, #1c8e81)";
export var colorChartsTeal500 = "var(--color-charts-teal-500-5p6o7k, #0d7d70)";
export var colorChartsTeal600 = "var(--color-charts-teal-600-1ar5in, #096f64)";
export var colorChartsTeal700 = "var(--color-charts-teal-700-3m4vci, #06645a)";
export var colorChartsTeal800 = "var(--color-charts-teal-800-0fx1b9, #045b52)";
export var colorChartsTeal900 = "var(--color-charts-teal-900-a0x7u4, #03524a)";
export var colorChartsTeal1000 = "var(--color-charts-teal-1000-n2wukj, #014b44)";
export var colorChartsTeal1100 = "var(--color-charts-teal-1100-3rvzfn, #01443e)";
export var colorChartsTeal1200 = "var(--color-charts-teal-1200-hfik8s, #003e38)";
export var colorChartsBlue1300 = "var(--color-charts-blue-1-300-uwr1wq, #529ccb)";
export var colorChartsBlue1400 = "var(--color-charts-blue-1-400-y4dmzv, #3184c2)";
export var colorChartsBlue1500 = "var(--color-charts-blue-1-500-jj2qqx, #0273bb)";
export var colorChartsBlue1600 = "var(--color-charts-blue-1-600-ntcnzw, #0166ab)";
export var colorChartsBlue1700 = "var(--color-charts-blue-1-700-vhr7bu, #015b9d)";
export var colorChartsBlue1800 = "var(--color-charts-blue-1-800-zq4na2, #015292)";
export var colorChartsBlue1900 = "var(--color-charts-blue-1-900-pu28p4, #014a87)";
export var colorChartsBlue11000 = "var(--color-charts-blue-1-1000-a1wpur, #01437d)";
export var colorChartsBlue11100 = "var(--color-charts-blue-1-1100-fyl0u6, #003c75)";
export var colorChartsBlue11200 = "var(--color-charts-blue-1-1200-y913fp, #00366d)";
export var colorChartsBlue2300 = "var(--color-charts-blue-2-300-sh1kxw, #688ae8)";
export var colorChartsBlue2400 = "var(--color-charts-blue-2-400-s269w5, #5978e3)";
export var colorChartsBlue2500 = "var(--color-charts-blue-2-500-y9grom, #4066df)";
export var colorChartsBlue2600 = "var(--color-charts-blue-2-600-ljsd9s, #3759ce)";
export var colorChartsBlue2700 = "var(--color-charts-blue-2-700-8pne2y, #314fbf)";
export var colorChartsBlue2800 = "var(--color-charts-blue-2-800-v3m6dn, #2c46b1)";
export var colorChartsBlue2900 = "var(--color-charts-blue-2-900-2rg9qw, #273ea5)";
export var colorChartsBlue21000 = "var(--color-charts-blue-2-1000-ore46l, #23379b)";
export var colorChartsBlue21100 = "var(--color-charts-blue-2-1100-rj1e0b, #1f3191)";
export var colorChartsBlue21200 = "var(--color-charts-blue-2-1200-i1tjtx, #1b2b88)";
export var colorChartsPurple300 = "var(--color-charts-purple-300-egsaob, #a783e1)";
export var colorChartsPurple400 = "var(--color-charts-purple-400-y2qlpj, #9469d6)";
export var colorChartsPurple500 = "var(--color-charts-purple-500-akrt38, #8456ce)";
export var colorChartsPurple600 = "var(--color-charts-purple-600-dnhyx7, #7749bf)";
export var colorChartsPurple700 = "var(--color-charts-purple-700-sk05tr, #6b40b2)";
export var colorChartsPurple800 = "var(--color-charts-purple-800-g5mys1, #6237a7)";
export var colorChartsPurple900 = "var(--color-charts-purple-900-ap3bee, #59309d)";
export var colorChartsPurple1000 = "var(--color-charts-purple-1000-pymhe3, #512994)";
export var colorChartsPurple1100 = "var(--color-charts-purple-1100-f2iopl, #4a238b)";
export var colorChartsPurple1200 = "var(--color-charts-purple-1200-aic8dr, #431d84)";
export var colorChartsPink300 = "var(--color-charts-pink-300-cctxfi, #da7596)";
export var colorChartsPink400 = "var(--color-charts-pink-400-kpryka, #ce567c)";
export var colorChartsPink500 = "var(--color-charts-pink-500-tccj7z, #c33d69)";
export var colorChartsPink600 = "var(--color-charts-pink-600-4bki3e, #b1325c)";
export var colorChartsPink700 = "var(--color-charts-pink-700-gexeia, #a32952)";
export var colorChartsPink800 = "var(--color-charts-pink-800-9fchm4, #962249)";
export var colorChartsPink900 = "var(--color-charts-pink-900-z4eeu8, #8b1b42)";
export var colorChartsPink1000 = "var(--color-charts-pink-1000-7uou4m, #81143b)";
export var colorChartsPink1100 = "var(--color-charts-pink-1100-j0uevl, #780d35)";
export var colorChartsPink1200 = "var(--color-charts-pink-1200-jpnl1t, #6f062f)";
export var colorChartsStatusCritical = "var(--color-charts-status-critical-vygogl, #7d2105)";
export var colorChartsStatusHigh = "var(--color-charts-status-high-xmh5vl, #ba2e0f)";
export var colorChartsStatusMedium = "var(--color-charts-status-medium-le4kdn, #cc5f21)";
export var colorChartsStatusLow = "var(--color-charts-status-low-seyj13, #b2911c)";
export var colorChartsStatusPositive = "var(--color-charts-status-positive-g1vr65, #67a353)";
export var colorChartsStatusInfo = "var(--color-charts-status-info-10v3c7, #3184c2)";
export var colorChartsStatusNeutral = "var(--color-charts-status-neutral-en4ep8, #8c8c94)";
export var colorChartsThresholdNegative = "var(--color-charts-threshold-negative-dj5crf, #db0000)";
export var colorChartsThresholdPositive = "var(--color-charts-threshold-positive-zo9s0l, #00802f)";
export var colorChartsThresholdInfo = "var(--color-charts-threshold-info-rgb5hd, #006ce0)";
export var colorChartsThresholdNeutral = "var(--color-charts-threshold-neutral-kq30cz, #656871)";
export var colorChartsLineGrid = "var(--color-charts-line-grid-vaxun1, #dedee3)";
export var colorChartsLineTick = "var(--color-charts-line-tick-6i81s1, #dedee3)";
export var colorChartsLineAxis = "var(--color-charts-line-axis-d4bcyf, #dedee3)";
export var colorChartsPaletteCategorical1 = "var(--color-charts-palette-categorical-1-ld9m1p, #688ae8)";
export var colorChartsPaletteCategorical2 = "var(--color-charts-palette-categorical-2-jwteam, #c33d69)";
export var colorChartsPaletteCategorical3 = "var(--color-charts-palette-categorical-3-tlwr5r, #2ea597)";
export var colorChartsPaletteCategorical4 = "var(--color-charts-palette-categorical-4-prlvih, #8456ce)";
export var colorChartsPaletteCategorical5 = "var(--color-charts-palette-categorical-5-04vsso, #e07941)";
export var colorChartsPaletteCategorical6 = "var(--color-charts-palette-categorical-6-2wgmsi, #3759ce)";
export var colorChartsPaletteCategorical7 = "var(--color-charts-palette-categorical-7-w06irv, #962249)";
export var colorChartsPaletteCategorical8 = "var(--color-charts-palette-categorical-8-1qnvcp, #096f64)";
export var colorChartsPaletteCategorical9 = "var(--color-charts-palette-categorical-9-0342zm, #6237a7)";
export var colorChartsPaletteCategorical10 = "var(--color-charts-palette-categorical-10-u73kns, #a84401)";
export var colorChartsPaletteCategorical11 = "var(--color-charts-palette-categorical-11-tloc2a, #273ea5)";
export var colorChartsPaletteCategorical12 = "var(--color-charts-palette-categorical-12-23xv0d, #780d35)";
export var colorChartsPaletteCategorical13 = "var(--color-charts-palette-categorical-13-tuq7ce, #03524a)";
export var colorChartsPaletteCategorical14 = "var(--color-charts-palette-categorical-14-bom4ss, #4a238b)";
export var colorChartsPaletteCategorical15 = "var(--color-charts-palette-categorical-15-kpqcpe, #7e3103)";
export var colorChartsPaletteCategorical16 = "var(--color-charts-palette-categorical-16-pzlo2l, #1b2b88)";
export var colorChartsPaletteCategorical17 = "var(--color-charts-palette-categorical-17-spn4mx, #ce567c)";
export var colorChartsPaletteCategorical18 = "var(--color-charts-palette-categorical-18-kxbv8i, #003e38)";
export var colorChartsPaletteCategorical19 = "var(--color-charts-palette-categorical-19-cl97gf, #9469d6)";
export var colorChartsPaletteCategorical20 = "var(--color-charts-palette-categorical-20-gzcnb4, #602400)";
export var colorChartsPaletteCategorical21 = "var(--color-charts-palette-categorical-21-vyqcuq, #4066df)";
export var colorChartsPaletteCategorical22 = "var(--color-charts-palette-categorical-22-6r7gq7, #a32952)";
export var colorChartsPaletteCategorical23 = "var(--color-charts-palette-categorical-23-7c98ot, #0d7d70)";
export var colorChartsPaletteCategorical24 = "var(--color-charts-palette-categorical-24-0i0wmq, #6b40b2)";
export var colorChartsPaletteCategorical25 = "var(--color-charts-palette-categorical-25-7j0tso, #bc4d01)";
export var colorChartsPaletteCategorical26 = "var(--color-charts-palette-categorical-26-eql7mt, #2c46b1)";
export var colorChartsPaletteCategorical27 = "var(--color-charts-palette-categorical-27-8doiqc, #81143b)";
export var colorChartsPaletteCategorical28 = "var(--color-charts-palette-categorical-28-tlstes, #045b52)";
export var colorChartsPaletteCategorical29 = "var(--color-charts-palette-categorical-29-noa7ft, #512994)";
export var colorChartsPaletteCategorical30 = "var(--color-charts-palette-categorical-30-ttbs6s, #8a3603)";
export var colorChartsPaletteCategorical31 = "var(--color-charts-palette-categorical-31-lz0ukh, #1f3191)";
export var colorChartsPaletteCategorical32 = "var(--color-charts-palette-categorical-32-ncpn1t, #da7596)";
export var colorChartsPaletteCategorical33 = "var(--color-charts-palette-categorical-33-fpbcye, #01443e)";
export var colorChartsPaletteCategorical34 = "var(--color-charts-palette-categorical-34-sk46nw, #a783e1)";
export var colorChartsPaletteCategorical35 = "var(--color-charts-palette-categorical-35-wwtyjx, #692801)";
export var colorChartsPaletteCategorical36 = "var(--color-charts-palette-categorical-36-7v0fgy, #5978e3)";
export var colorChartsPaletteCategorical37 = "var(--color-charts-palette-categorical-37-b2rc0w, #b1325c)";
export var colorChartsPaletteCategorical38 = "var(--color-charts-palette-categorical-38-nlzlk1, #1c8e81)";
export var colorChartsPaletteCategorical39 = "var(--color-charts-palette-categorical-39-esgczm, #7749bf)";
export var colorChartsPaletteCategorical40 = "var(--color-charts-palette-categorical-40-0hdln6, #cc5f21)";
export var colorChartsPaletteCategorical41 = "var(--color-charts-palette-categorical-41-bsk8cf, #314fbf)";
export var colorChartsPaletteCategorical42 = "var(--color-charts-palette-categorical-42-97om84, #8b1b42)";
export var colorChartsPaletteCategorical43 = "var(--color-charts-palette-categorical-43-euzcqg, #06645a)";
export var colorChartsPaletteCategorical44 = "var(--color-charts-palette-categorical-44-a7wos6, #59309d)";
export var colorChartsPaletteCategorical45 = "var(--color-charts-palette-categorical-45-iz0wz2, #983c02)";
export var colorChartsPaletteCategorical46 = "var(--color-charts-palette-categorical-46-nzigym, #23379b)";
export var colorChartsPaletteCategorical47 = "var(--color-charts-palette-categorical-47-croxgd, #6f062f)";
export var colorChartsPaletteCategorical48 = "var(--color-charts-palette-categorical-48-tptw1p, #014b44)";
export var colorChartsPaletteCategorical49 = "var(--color-charts-palette-categorical-49-6lrkes, #431d84)";
export var colorChartsPaletteCategorical50 = "var(--color-charts-palette-categorical-50-mqbaxr, #732c02)";
export var colorSeverityDarkRed = "var(--color-severity-dark-red-26aton, #870303)";
export var colorSeverityRed = "var(--color-severity-red-mjb3fl, #ce3311)";
export var colorSeverityOrange = "var(--color-severity-orange-3ez4cr, #f89256)";
export var colorSeverityYellow = "var(--color-severity-yellow-mn9wrz, #f2cd54)";
export var colorSeverityGrey = "var(--color-severity-grey-s6nlyg, #656871)";
export var colorBackgroundNotificationSeverityCritical = "var(--color-background-notification-severity-critical-m6z340, #870303)";
export var colorBackgroundNotificationSeverityHigh = "var(--color-background-notification-severity-high-zn7yde, #ce3311)";
export var colorBackgroundNotificationSeverityMedium = "var(--color-background-notification-severity-medium-empymi, #f89256)";
export var colorBackgroundNotificationSeverityLow = "var(--color-background-notification-severity-low-jkxbp4, #f2cd54)";
export var colorBackgroundNotificationSeverityNeutral = "var(--color-background-notification-severity-neutral-wtuxqz, #656871)";
export var colorTextNotificationSeverityCritical = "var(--color-text-notification-severity-critical-cmj0dk, #f9f9fa)";
export var colorTextNotificationSeverityHigh = "var(--color-text-notification-severity-high-rgh0m2, #f9f9fa)";
export var colorTextNotificationSeverityMedium = "var(--color-text-notification-severity-medium-9rk0hm, #0f141a)";
export var colorTextNotificationSeverityLow = "var(--color-text-notification-severity-low-so8wqb, #0f141a)";
export var colorTextNotificationSeverityNeutral = "var(--color-text-notification-severity-neutral-h04hdx, #f9f9fa)";
export var colorGreyOpaque10 = "var(--color-grey-opaque-10-xipm4s, rgba(0, 0, 0, 0.1))";
export var colorGreyOpaque25 = "var(--color-grey-opaque-25-igqnxy, rgba(255, 255, 255, 0.25))";
export var colorGreyOpaque40 = "var(--color-grey-opaque-40-wxjof3, rgba(0, 0, 0, 0.4))";
export var colorGreyOpaque50 = "var(--color-grey-opaque-50-akurqr, rgba(0, 0, 0, 0.5))";
export var colorGreyOpaque70 = "var(--color-grey-opaque-70-jp2m2h, rgba(35, 43, 55, 0.7))";
export var colorGreyOpaque80 = "var(--color-grey-opaque-80-dkpq62, rgba(22, 25, 31, 0.8))";
export var colorGreyOpaque90 = "var(--color-grey-opaque-90-vv6tpi, rgba(242, 243, 243, 0.9))";
export var colorGreyTransparent = "var(--color-grey-transparent-pgkfzu, rgba(15, 20, 26, 0.12))";
export var colorGreyTransparentHeavy = "var(--color-grey-transparent-heavy-vx6iel, rgba(15, 20, 26, 0.12))";
export var colorGreyTransparentLight = "var(--color-grey-transparent-light-qjg35b, rgba(15, 20, 26, 0.12))";
export var colorBackgroundBadgeIcon = "var(--color-background-badge-icon-n0lc3o, #db0000)";
export var colorBackgroundButtonLinkActive = "var(--color-background-button-link-active-zsvqyt, #d1f1ff)";
export var colorBackgroundButtonLinkHover = "var(--color-background-button-link-hover-7joibg, #f0fbff)";
export var colorBackgroundButtonNormalActive = "var(--color-background-button-normal-active-7b2v4r, #D0D0D9)";
export var colorBackgroundButtonNormalDefault = "var(--color-background-button-normal-default-mddweg, #ffffff)";
export var colorBackgroundButtonNormalDisabled = "var(--color-background-button-normal-disabled-0bsj74, #FFFFFF)";
export var colorBackgroundButtonNormalHover = "var(--color-background-button-normal-hover-afnhvc, #EDEDF2)";
export var colorBackgroundToggleButtonNormalPressed = "var(--color-background-toggle-button-normal-pressed-cpfehj, #d1f1ff)";
export var colorBackgroundButtonPrimaryActive = "var(--color-background-button-primary-active-zs72io, #079589)";
export var colorBackgroundButtonPrimaryDefault = "var(--color-background-button-primary-default-5t8xid, #00DECB)";
export var colorBackgroundButtonPrimaryDisabled = "var(--color-background-button-primary-disabled-l4i17m, #ebebf0)";
export var colorBackgroundButtonPrimaryHover = "var(--color-background-button-primary-hover-3lfnqb, #15BEB0)";
export var colorBackgroundCalendarCurrentDate = "var(--color-background-calendar-current-date-a6ucrp, #f3f3f7)";
export var colorBackgroundCellShaded = "var(--color-background-cell-shaded-sc089k, #f6f6f9)";
export var colorBackgroundCodeEditorGutterActiveLineDefault = "var(--color-background-code-editor-gutter-active-line-default-v8is2q, #656871)";
export var colorBackgroundCodeEditorGutterActiveLineError = "var(--color-background-code-editor-gutter-active-line-error-bd4ln4, #db0000)";
export var colorBackgroundCodeEditorGutterDefault = "var(--color-background-code-editor-gutter-default-v5e78w, #f3f3f7)";
export var colorBackgroundCodeEditorLoading = "var(--color-background-code-editor-loading-2zupgi, #f9f9fa)";
export var colorBackgroundCodeEditorPaneItemHover = "var(--color-background-code-editor-pane-item-hover-h04m4p, #ebebf0)";
export var colorBackgroundCodeEditorStatusBar = "var(--color-background-code-editor-status-bar-ybpgwu, #f3f3f7)";
export var colorBackgroundContainerContent = "var(--color-background-container-content-gvlnlg, #FFFFFF)";
export var colorBackgroundContainerHeader = "var(--color-background-container-header-tppqn8, #FFFFFF)";
export var colorBackgroundControlChecked = "var(--color-background-control-checked-ayqjql, #00DECB)";
export var colorBackgroundControlDefault = "var(--color-background-control-default-vajyob, #ffffff)";
export var colorBackgroundControlDisabled = "var(--color-background-control-disabled-8jpzfw, #D0D0D9)";
export var colorBackgroundDropdownItemDefault = "var(--color-background-dropdown-item-default-2oc8rg, #ffffff)";
export var colorBackgroundDropdownItemDimmed = "var(--color-background-dropdown-item-dimmed-dlm6wk, transparent)";
export var colorBackgroundDropdownItemFilterMatch = "var(--color-background-dropdown-item-filter-match-5pchhq, #f0fbff)";
export var colorBackgroundDropdownItemHover = "var(--color-background-dropdown-item-hover-t0v1ik, #EDEDF2)";
export var colorBackgroundDropdownItemSelected = "var(--color-background-dropdown-item-selected-c5gnz0, #ECFBFA)";
export var colorBackgroundHomeHeader = "var(--color-background-home-header-h71bgo, #0F0F2D)";
export var colorBackgroundInputDefault = "var(--color-background-input-default-7vkaci, rgba(0,0,0,0))";
export var colorBackgroundInputDisabled = "var(--color-background-input-disabled-gvxsk4, #ebebf0)";
export var colorBackgroundItemSelected = "var(--color-background-item-selected-n2041r, #ECFBFA)";
export var colorBackgroundLayoutMain = "var(--color-background-layout-main-q1co0c, #F6F6FB)";
export var colorBackgroundLayoutMobilePanel = "var(--color-background-layout-mobile-panel-o50s90, #0f141a)";
export var colorBackgroundLayoutPanelContent = "var(--color-background-layout-panel-content-i5ojqk, #FFFFFF)";
export var colorBackgroundLayoutPanelHover = "var(--color-background-layout-panel-hover-1ftnua, #ebebf0)";
export var colorBackgroundLayoutToggleActive = "var(--color-background-layout-toggle-active-5x3zwa, #424650)";
export var colorBackgroundLayoutToggleDefault = "var(--color-background-layout-toggle-default-kci9ri, #424650)";
export var colorBackgroundLayoutToggleHover = "var(--color-background-layout-toggle-hover-72w0bh, #656871)";
export var colorBackgroundLayoutToggleSelectedActive = "var(--color-background-layout-toggle-selected-active-tv8i8b, #006ce0)";
export var colorBackgroundLayoutToggleSelectedDefault = "var(--color-background-layout-toggle-selected-default-27rnk6, #006ce0)";
export var colorBackgroundLayoutToggleSelectedHover = "var(--color-background-layout-toggle-selected-hover-eh8bga, #004a9e)";
export var colorBackgroundModalOverlay = "var(--color-background-modal-overlay-lal4at, rgba(35, 43, 55, 0.7))";
export var colorBackgroundNotificationBlue = "var(--color-background-notification-blue-g87klk, #006ce0)";
export var colorBackgroundNotificationGreen = "var(--color-background-notification-green-7zevmb, #00802f)";
export var colorBackgroundNotificationGrey = "var(--color-background-notification-grey-jyn3j2, #424650)";
export var colorBackgroundNotificationRed = "var(--color-background-notification-red-f0cy22, #db0000)";
export var colorBackgroundNotificationYellow = "var(--color-background-notification-yellow-4teyr2, #ffe347)";
export var colorBackgroundNotificationStackBar = "var(--color-background-notification-stack-bar-fl11np, #232b37)";
export var colorBackgroundNotificationStackBarActive = "var(--color-background-notification-stack-bar-active-4ibdhd, #232b37)";
export var colorBackgroundNotificationStackBarHover = "var(--color-background-notification-stack-bar-hover-z4ly3h, #424650)";
export var colorBackgroundPopover = "var(--color-background-popover-v7olus, #ffffff)";
export var colorBackgroundProgressBarContentDefault = "var(--color-background-progress-bar-content-default-sn7ya6, #006ce0)";
export var colorBackgroundProgressBarContentInFlash = "var(--color-background-progress-bar-content-in-flash-ikihd5, #ffffff)";
export var colorBackgroundProgressBarLayoutDefault = "var(--color-background-progress-bar-layout-default-urc8t0, #ebebf0)";
export var colorBackgroundProgressBarLayoutInFlash = "var(--color-background-progress-bar-layout-in-flash-d3q12s, rgba(255, 255, 255, 0.25))";
export var colorBackgroundSegmentActive = "var(--color-background-segment-active-bkvyiy, #006ce0)";
export var colorBackgroundSegmentDefault = "var(--color-background-segment-default-voaiuw, #ffffff)";
export var colorBackgroundSegmentDisabled = "var(--color-background-segment-disabled-nh22sg, #FFFFFF)";
export var colorBackgroundSegmentHover = "var(--color-background-segment-hover-jbzuvp, #ffffff)";
export var colorBackgroundSliderHandleDefault = "var(--color-background-slider-handle-default-94lnn1, #006ce0)";
export var colorBackgroundSliderHandleActive = "var(--color-background-slider-handle-active-7w7xg0, #004a9e)";
export var colorBackgroundSliderTrack = "var(--color-background-slider-track-uhckut, #8c8c94)";
export var colorBackgroundSliderHandleRing = "var(--color-background-slider-handle-ring-nibud6, #ffffff)";
export var colorBackgroundSliderErrorPressed = "var(--color-background-slider-error-pressed-j572od, #700000)";
export var colorBackgroundSliderWarningPressed = "var(--color-background-slider-warning-pressed-pg5aih, #855900)";
export var colorBackgroundStatusError = "var(--color-background-status-error-h57bgq, #fff5f5)";
export var colorBackgroundStatusInfo = "var(--color-background-status-info-jsnsxa, #f0fbff)";
export var colorBackgroundStatusSuccess = "var(--color-background-status-success-ua29ej, #effff1)";
export var colorBackgroundStatusWarning = "var(--color-background-status-warning-2oc197, #fffef0)";
export var colorBackgroundTableHeader = "var(--color-background-table-header-cqcff9, #FFFFFF)";
export var colorBackgroundTilesDisabled = "var(--color-background-tiles-disabled-ozblza, #ebebf0)";
export var colorBackgroundToggleCheckedDisabled = "var(--color-background-toggle-checked-disabled-1m2ufy, #D0D0D9)";
export var colorBackgroundToggleDefault = "var(--color-background-toggle-default-ywflhx, #424650)";
export var colorBackgroundAvatarGenAi = "var(--color-background-avatar-gen-ai-i9hcw6, radial-gradient(circle farthest-corner at top left,rgba(0, 150, 250, 1) -25%,rgba(0, 150, 250, 0) 55%),radial-gradient(circle farthest-corner at top right, rgba(216, 178, 255, 1) -10%, rgba(115, 0, 229, 1) 50%))";
export var colorBackgroundAvatarDefault = "var(--color-background-avatar-default-rci9zy, #424650)";
export var colorTextAvatar = "var(--color-text-avatar-jddpvm, #ffffff)";
export var colorBackgroundLoadingBarGenAi = "var(--color-background-loading-bar-gen-ai-7qfi9s, linear-gradient(90deg, #99f7ff 0%, #0096fa 10%, #bf80ff 24%, #7300e5 50%, #bf80ff 76%, #0096fa 90%, #99f7ff 100%))";
export var colorBackgroundChatBubbleOutgoing = "var(--color-background-chat-bubble-outgoing-pfqan2, transparent)";
export var colorBackgroundChatBubbleIncoming = "var(--color-background-chat-bubble-incoming-8pi0ow, #f6f6f9)";
export var colorTextChatBubbleOutgoing = "var(--color-text-chat-bubble-outgoing-uxk1hp, #2D2D53)";
export var colorTextChatBubbleIncoming = "var(--color-text-chat-bubble-incoming-xwww0c, #2D2D53)";
export var colorBorderButtonNormalActive = "var(--color-border-button-normal-active-zo7wt9, #002b66)";
export var colorBorderButtonNormalDefault = "var(--color-border-button-normal-default-oytrjp, #B9B9C6)";
export var colorBorderToggleButtonNormalPressed = "var(--color-border-toggle-button-normal-pressed-lylxt2, #006ce0)";
export var colorBorderButtonNormalDisabled = "var(--color-border-button-normal-disabled-caqkhj, #b4b4bb)";
export var colorTextButtonNormalDisabled = "var(--color-text-button-normal-disabled-0s30to, #8c8c94)";
export var colorBorderButtonNormalHover = "var(--color-border-button-normal-hover-uafmsh, #EDEDF2)";
export var colorBorderButtonPrimaryDisabled = "var(--color-border-button-primary-disabled-xtv38q, #ebebf0)";
export var colorTextButtonPrimaryDisabled = "var(--color-text-button-primary-disabled-klolb5, #8c8c94)";
export var colorBorderCalendarGrid = "var(--color-border-calendar-grid-u86q1u, transparent)";
export var colorBorderCalendarGridSelectedFocusRing = "var(--color-border-calendar-grid-selected-focus-ring-w7gc9h, #f9f9fa)";
export var colorBorderCodeEditorAceActiveLineLightTheme = "var(--color-border-code-editor-ace-active-line-light-theme-3a7k5r, #dedee3)";
export var colorBorderCodeEditorAceActiveLineDarkTheme = "var(--color-border-code-editor-ace-active-line-dark-theme-8s541m, #656871)";
export var colorBorderCodeEditorDefault = "var(--color-border-code-editor-default-u6q2xy, #dedee3)";
export var colorBorderCodeEditorPaneItemHover = "var(--color-border-code-editor-pane-item-hover-qonm1g, #8c8c94)";
export var colorBorderContainerDivider = "var(--color-border-container-divider-p2uygo, transparent)";
export var colorBorderContainerTop = "var(--color-border-container-top-736buh, transparent)";
export var colorBorderControlChecked = "var(--color-border-control-checked-b1yd93, #00DECB)";
export var colorBorderControlDefault = "var(--color-border-control-default-8m5t80, #8c8c94)";
export var colorBorderControlDisabled = "var(--color-border-control-disabled-utf6ky, #D0D0D9)";
export var colorBorderDividerActive = "var(--color-border-divider-active-tn8t2p, #0f141a)";
export var colorBorderDividerDefault = "var(--color-border-divider-default-oo7i1s, #EDEDF2)";
export var colorBorderDividerPanelBottom = "var(--color-border-divider-panel-bottom-8crweu, #EDEDF2)";
export var colorBorderDividerPanelSide = "var(--color-border-divider-panel-side-suoigo, #EDEDF2)";
export var colorBorderDividerSecondary = "var(--color-border-divider-secondary-4fnj6z, #EDEDF2)";
export var colorBorderDropdownContainer = "var(--color-border-dropdown-container-fo7aoz, #b4b4bb)";
export var colorBorderDropdownGroup = "var(--color-border-dropdown-group-hcbyyt, #EDEDF2)";
export var colorBorderDropdownItemDefault = "var(--color-border-dropdown-item-default-zkaoav, #EDEDF2)";
export var colorBorderDropdownItemHover = "var(--color-border-dropdown-item-hover-4p0gsi, #8c8c94)";
export var colorBorderDropdownItemDimmedHover = "var(--color-border-dropdown-item-dimmed-hover-6qayyi, #8c8c94)";
export var colorBorderDropdownItemSelected = "var(--color-border-dropdown-item-selected-q6guyc, #00DECB)";
export var colorBorderDropdownItemTop = "var(--color-border-dropdown-item-top-ky64jq, transparent)";
export var colorBorderEditableCellHover = "var(--color-border-editable-cell-hover-3ju944, #8c8c94)";
export var colorBorderInputDefault = "var(--color-border-input-default-wwqqee, #B9B9C6)";
export var colorBorderInputDisabled = "var(--color-border-input-disabled-lb7zn3, #ebebf0)";
export var colorBorderInputFocused = "var(--color-border-input-focused-c6w5i5, #002b66)";
export var colorBorderItemFocused = "var(--color-border-item-focused-r5zi19, #00DECB)";
export var colorBorderDropdownItemFocused = "var(--color-border-dropdown-item-focused-5afjvu, #424650)";
export var colorBorderItemPlaceholder = "var(--color-border-item-placeholder-f3cj7j, #00DECB)";
export var colorBorderItemSelected = "var(--color-border-item-selected-5btja7, #00DECB)";
export var colorBorderLayout = "var(--color-border-layout-hrw5xj, #b4b4bb)";
export var colorBorderNotificationStackBar = "var(--color-border-notification-stack-bar-hrqqk8, #232b37)";
export var colorBorderPanelHeader = "var(--color-border-panel-header-rmx56r, #EDEDF2)";
export var colorBorderPopover = "var(--color-border-popover-v2sc7l, #b4b4bb)";
export var colorBorderSegmentActive = "var(--color-border-segment-active-0hw1oi, #424650)";
export var colorBorderSegmentDefault = "var(--color-border-segment-default-qegins, #424650)";
export var colorBorderSegmentDisabled = "var(--color-border-segment-disabled-jfhyf7, #424650)";
export var colorBorderSegmentHover = "var(--color-border-segment-hover-cs1sac, #424650)";
export var colorBorderStatusError = "var(--color-border-status-error-adxa56, #db0000)";
export var colorBorderStatusInfo = "var(--color-border-status-info-gtr1tm, #006ce0)";
export var colorBorderStatusSuccess = "var(--color-border-status-success-4x6srk, #00802f)";
export var colorBorderStatusWarning = "var(--color-border-status-warning-jxib9t, #855900)";
export var colorBorderDividerInteractiveDefault = "var(--color-border-divider-interactive-default-0c10au, #8c8c94)";
export var colorBorderTabsDivider = "var(--color-border-tabs-divider-djfdfj, #c6c6cd)";
export var colorBorderTabsShadow = "var(--color-border-tabs-shadow-enjrr2, rgba(15, 20, 26, 0.12))";
export var colorBorderTabsUnderline = "var(--color-border-tabs-underline-87n35h, #00DECB)";
export var colorBorderTilesDisabled = "var(--color-border-tiles-disabled-9w9xuo, #ebebf0)";
export var colorBorderTutorial = "var(--color-border-tutorial-upbl0e, #dedee3)";
export var colorForegroundControlDefault = "var(--color-foreground-control-default-y70pbi, #ffffff)";
export var colorForegroundControlDisabled = "var(--color-foreground-control-disabled-sllop1, #ffffff)";
export var colorForegroundControlReadOnly = "var(--color-foreground-control-read-only-e43ooq, #656871)";
export var colorShadowDefault = "var(--color-shadow-default-u7prpu, rgba(15, 20, 26, 0.12))";
export var colorShadowMedium = "var(--color-shadow-medium-aogong, rgba(15, 20, 26, 0.12))";
export var colorShadowSide = "var(--color-shadow-side-htkwff, rgba(15, 20, 26, 0.12))";
export var colorStrokeChartLine = "var(--color-stroke-chart-line-i586tw, #8c8c94)";
export var colorStrokeCodeEditorResizeHandler = "var(--color-stroke-code-editor-resize-handler-nn8o8u, #424650)";
export var colorStrokeCodeEditorGutterActiveLineDefault = "var(--color-stroke-code-editor-gutter-active-line-default-s9ueow, #dedee3)";
export var colorStrokeCodeEditorGutterActiveLineHover = "var(--color-stroke-code-editor-gutter-active-line-hover-8p756l, #f9f9fa)";
export var colorTextAccent = "var(--color-text-accent-5whczl, #00DECB)";
export var colorTextBodyDefault = "var(--color-text-body-default-9v2g3l, #2D2D53)";
export var colorTextBodySecondary = "var(--color-text-body-secondary-cwla8d, #424650)";
export var colorTextBreadcrumbCurrent = "var(--color-text-breadcrumb-current-199qm9, #656871)";
export var colorTextBreadcrumbIcon = "var(--color-text-breadcrumb-icon-f36kfl, #8c8c94)";
export var colorTextButtonInlineIconDefault = "var(--color-text-button-inline-icon-default-mnaume, #5C5C79)";
export var colorTextButtonInlineIconDisabled = "var(--color-text-button-inline-icon-disabled-yk9lvs, #b4b4bb)";
export var colorTextButtonInlineIconHover = "var(--color-text-button-inline-icon-hover-fpd79e, #0F0F2D)";
export var colorTextButtonNormalActive = "var(--color-text-button-normal-active-7p0tut, #002b66)";
export var colorTextToggleButtonNormalPressed = "var(--color-text-toggle-button-normal-pressed-n7ydno, #002b66)";
export var colorTextButtonNormalDefault = "var(--color-text-button-normal-default-zn42s1, #2D2D53)";
export var colorTextButtonNormalHover = "var(--color-text-button-normal-hover-5bfpto, #2D2D53)";
export var colorTextLinkButtonNormalDefault = "var(--color-text-link-button-normal-default-ngc5hg, #2D2D53)";
export var colorTextLinkButtonNormalHover = "var(--color-text-link-button-normal-hover-ukr963, #2D2D53)";
export var colorTextLinkButtonNormalActive = "var(--color-text-link-button-normal-active-29iuxy, #002b66)";
export var colorTextButtonPrimaryActive = "var(--color-text-button-primary-active-qimpcl, #FFFFFF)";
export var colorTextButtonPrimaryDefault = "var(--color-text-button-primary-default-uu8c6p, #2D2D53)";
export var colorTextButtonPrimaryHover = "var(--color-text-button-primary-hover-ph2ly5, #FFFFFF)";
export var colorTextCalendarDateHover = "var(--color-text-calendar-date-hover-q3eoye, #121233)";
export var colorTextCalendarDateSelected = "var(--color-text-calendar-date-selected-ofb8hc, #00DECB)";
export var colorTextCalendarMonth = "var(--color-text-calendar-month-7dq80e, #656871)";
export var colorTextCodeEditorGutterActiveLine = "var(--color-text-code-editor-gutter-active-line-xn0lux, #ffffff)";
export var colorTextCodeEditorGutterDefault = "var(--color-text-code-editor-gutter-default-wzd6uj, #0f141a)";
export var colorTextCodeEditorStatusBarDisabled = "var(--color-text-code-editor-status-bar-disabled-wlvfa0, #8c8c94)";
export var colorTextCodeEditorTabButtonError = "var(--color-text-code-editor-tab-button-error-491d5d, #ffffff)";
export var colorTextColumnHeader = "var(--color-text-column-header-18yqaj, #424650)";
export var colorTextColumnSortingIcon = "var(--color-text-column-sorting-icon-4y2if8, #424650)";
export var colorTextControlDisabled = "var(--color-text-control-disabled-7u2yiq, #b4b4bb)";
export var colorTextCounter = "var(--color-text-counter-goqopo, #656871)";
export var colorTextDisabled = "var(--color-text-disabled-e4vsj4, #b4b4bb)";
export var colorTextDisabledInlineEdit = "var(--color-text-disabled-inline-edit-rcb2pm, #424650)";
export var colorTextDropdownFooter = "var(--color-text-dropdown-footer-1jyb4n, #656871)";
export var colorTextDropdownGroupLabel = "var(--color-text-dropdown-group-label-iy2p4t, #424650)";
export var colorTextDropdownItemDefault = "var(--color-text-dropdown-item-default-xl5hyk, #121233)";
export var colorTextDropdownItemDimmed = "var(--color-text-dropdown-item-dimmed-7c5xo0, #b4b4bb)";
export var colorTextDropdownItemDisabled = "var(--color-text-dropdown-item-disabled-7fg5u8, #b4b4bb)";
export var colorTextDropdownItemFilterMatch = "var(--color-text-dropdown-item-filter-match-spr81m, #006ce0)";
export var colorTextDropdownItemHighlighted = "var(--color-text-dropdown-item-highlighted-3jsjee, #2D2D53)";
export var colorTextDropdownItemSecondary = "var(--color-text-dropdown-item-secondary-xvh5zo, #656871)";
export var colorTextDropdownItemSecondaryHover = "var(--color-text-dropdown-item-secondary-hover-4xn5ec, #656871)";
export var colorTextEmpty = "var(--color-text-empty-o6660v, #656871)";
export var colorTextExpandableSectionDefault = "var(--color-text-expandable-section-default-ek5lxm, #0f141a)";
export var colorTextExpandableSectionHover = "var(--color-text-expandable-section-hover-c0r34q, #00DECB)";
export var colorTextExpandableSectionNavigationIconDefault = "var(--color-text-expandable-section-navigation-icon-default-tbe28s, #424650)";
export var colorTextFormDefault = "var(--color-text-form-default-2j5bxm, #121233)";
export var colorTextFormLabel = "var(--color-text-form-label-86mgfu, #121233)";
export var colorTextFormSecondary = "var(--color-text-form-secondary-54emib, #656871)";
export var colorTextGroupLabel = "var(--color-text-group-label-8pk3es, #424650)";
export var colorTextLabelGenAi = "var(--color-text-label-gen-ai-76ots7, #7300e5)";
export var colorTextHeadingDefault = "var(--color-text-heading-default-qmlmwp, #2D2D53)";
export var colorTextHeadingSecondary = "var(--color-text-heading-secondary-0myrp3, #424650)";
export var colorTextHomeHeaderDefault = "var(--color-text-home-header-default-ressfo, #ebebf0)";
export var colorTextHomeHeaderSecondary = "var(--color-text-home-header-secondary-iqyyqx, #c6c6cd)";
export var colorTextIconCaret = "var(--color-text-icon-caret-t91iw3, #8c8c94)";
export var colorTextIconSubtle = "var(--color-text-icon-subtle-pt80lq, #656871)";
export var colorTextInputDisabled = "var(--color-text-input-disabled-fc4jfl, #b4b4bb)";
export var colorTextInputPlaceholder = "var(--color-text-input-placeholder-f3x213, #656871)";
export var colorTextInputPlaceholderDisabled = "var(--color-text-input-placeholder-disabled-7v2f7f, #b4b4bb)";
export var colorTextInteractiveActive = "var(--color-text-interactive-active-j6vw94, #0f141a)";
export var colorTextInteractiveDefault = "var(--color-text-interactive-default-tkx8fe, #424650)";
export var colorTextInteractiveDisabled = "var(--color-text-interactive-disabled-f5b4z5, #b4b4bb)";
export var colorTextInteractiveHover = "var(--color-text-interactive-hover-aktkyr, #2D2D53)";
export var colorTextToggleButtonIconPressed = "var(--color-text-toggle-button-icon-pressed-d40d50, #0f141a)";
export var colorTextInteractiveInvertedDefault = "var(--color-text-interactive-inverted-default-8v8d60, #dedee3)";
export var colorTextInteractiveInvertedHover = "var(--color-text-interactive-inverted-hover-0r4deg, #f9f9fa)";
export var colorTextInverted = "var(--color-text-inverted-pvc88w, #ffffff)";
export var colorTextLabel = "var(--color-text-label-ybz40g, #121233)";
export var colorTextLayoutToggle = "var(--color-text-layout-toggle-zybz0r, #ffffff)";
export var colorTextLayoutToggleActive = "var(--color-text-layout-toggle-active-as4nbt, #ffffff)";
export var colorTextLayoutToggleHover = "var(--color-text-layout-toggle-hover-v3lnw5, #006ce0)";
export var colorTextLayoutToggleSelected = "var(--color-text-layout-toggle-selected-kb3p9r, #ffffff)";
export var colorTextLinkDefault = "var(--color-text-link-default-uuhyb3, #5C5C79)";
export var colorTextLinkHover = "var(--color-text-link-hover-wgkmog, #0F0F2D)";
export var colorTextLinkInvertedHover = "var(--color-text-link-inverted-hover-zsv0ev, #ffffff)";
export var colorTextLinkButtonUnderline = "var(--color-text-link-button-underline-ik5r9i, transparent)";
export var colorTextLinkButtonUnderlineHover = "var(--color-text-link-button-underline-hover-gyir88, transparent)";
export var colorTextNotificationDefault = "var(--color-text-notification-default-0l2rzu, #f9f9fa)";
export var colorTextNotificationStackBar = "var(--color-text-notification-stack-bar-jc9tvx, #ffffff)";
export var colorTextNotificationYellow = "var(--color-text-notification-yellow-p8f867, #0f141a)";
export var colorTextPaginationPageNumberActiveDisabled = "var(--color-text-pagination-page-number-active-disabled-a19yjt, #b4b4bb)";
export var colorTextPaginationPageNumberDefault = "var(--color-text-pagination-page-number-default-s25kr0, #424650)";
export var colorTextSegmentActive = "var(--color-text-segment-active-fc6owc, #ffffff)";
export var colorTextSegmentDefault = "var(--color-text-segment-default-iib3us, #424650)";
export var colorTextSegmentHover = "var(--color-text-segment-hover-88u58t, #006ce0)";
export var colorTextSmall = "var(--color-text-small-nzfntg, #656871)";
export var colorTextStatusError = "var(--color-text-status-error-5676bj, #db0000)";
export var colorTextStatusInactive = "var(--color-text-status-inactive-5megna, #656871)";
export var colorTextStatusInfo = "var(--color-text-status-info-g2ezdf, #00DECB)";
export var colorTextStatusSuccess = "var(--color-text-status-success-gqp5xk, #00802f)";
export var colorTextStatusWarning = "var(--color-text-status-warning-k8v003, #855900)";
export var colorTextTopNavigationTitle = "var(--color-text-top-navigation-title-v9cb3m, #0f141a)";
export var colorBoardPlaceholderActive = "var(--color-board-placeholder-active-93q92g, #ebebf0)";
export var colorBoardPlaceholderHover = "var(--color-board-placeholder-hover-yu7ixx, #d1f1ff)";
export var colorDragPlaceholderActive = "var(--color-drag-placeholder-active-llvhbu, #ebebf0)";
export var colorDragPlaceholderHover = "var(--color-drag-placeholder-hover-oikifl, #d1f1ff)";
export var colorDropzoneBackgroundDefault = "var(--color-dropzone-background-default-q8kl4f, #ffffff)";
export var colorDropzoneBackgroundHover = "var(--color-dropzone-background-hover-wl3yez, #f0fbff)";
export var colorDropzoneTextDefault = "var(--color-dropzone-text-default-owo5k2, #424650)";
export var colorDropzoneTextHover = "var(--color-dropzone-text-hover-wteupn, #424650)";
export var colorDropzoneBorderDefault = "var(--color-dropzone-border-default-phwpnt, #8c8c94)";
export var colorDropzoneBorderHover = "var(--color-dropzone-border-hover-tpluhc, #002b66)";
export var fontBoxValueLargeWeight = "var(--font-box-value-large-weight-onoq9k, 700)";
export var fontButtonLetterSpacing = "var(--font-button-letter-spacing-65ug0o, 0.005em)";
export var fontChartDetailSize = "var(--font-chart-detail-size-1n389u, 12px)";
export var fontDisplayLabelWeight = "var(--font-display-label-weight-815ja9, 700)";
export var fontExpandableHeadingSize = "var(--font-expandable-heading-size-smdiul, 16px)";
export var fontFamilyBase = "var(--font-family-base-ck7vn5, Sora, sans-serif)";
export var fontFamilyMonospace = "var(--font-family-monospace-bftdwf, Monaco, Menlo, Consolas, 'Courier Prime', Courier, 'Courier New', monospace)";
export var fontHeaderH2DescriptionLineHeight = "var(--font-header-h2-description-line-height-rtewek, 20px)";
export var fontHeaderH2DescriptionSize = "var(--font-header-h2-description-size-2hj97p, 14px)";
export var fontLinkButtonLetterSpacing = "var(--font-link-button-letter-spacing-yi9f0l, 0.005em)";
export var fontLinkButtonWeight = "var(--font-link-button-weight-jephf1, 700)";
export var fontPanelHeaderLineHeight = "var(--font-panel-header-line-height-kxi4u2, 22px)";
export var fontPanelHeaderSize = "var(--font-panel-header-size-edjz3l, 18px)";
export var fontSizeBodyM = "var(--font-size-body-m-x4okxb, 14px)";
export var fontSizeBodyS = "var(--font-size-body-s-asqx2i, 12px)";
export var fontSizeDisplayL = "var(--font-size-display-l-htkq3e, 42px)";
export var fontSizeHeadingXl = "var(--font-size-heading-xl-ypc05a, 24px)";
export var fontSizeHeadingL = "var(--font-size-heading-l-ou0d0l, 20px)";
export var fontSizeHeadingM = "var(--font-size-heading-m-sjd256, 18px)";
export var fontSizeHeadingS = "var(--font-size-heading-s-yykzif, 16px)";
export var fontSizeHeadingXs = "var(--font-size-heading-xs-wo4hpf, 14px)";
export var fontSmoothingMozOsx = "var(--font-smoothing-moz-osx-foywby, grayscale)";
export var fontSmoothingWebkit = "var(--font-smoothing-webkit-m8nc84, antialiased)";
export var fontTabsDisabledWeight = "var(--font-tabs-disabled-weight-1xcs5l, 700)";
export var fontTabsLineHeight = "var(--font-tabs-line-height-dzo7k3, 20px)";
export var fontTabsSize = "var(--font-tabs-size-8zzqp2, 16px)";
export var fontWayfindingLinkActiveWeight = "var(--font-wayfinding-link-active-weight-rbmzei, 700)";
export var fontWeightButton = "var(--font-weight-button-c91p4t, 700)";
export var fontWeightHeadingXl = "var(--font-weight-heading-xl-yvsksd, 700)";
export var fontWeightHeadingL = "var(--font-weight-heading-l-f8711v, 700)";
export var fontWeightHeadingM = "var(--font-weight-heading-m-m2ekmb, 700)";
export var fontWeightHeadingS = "var(--font-weight-heading-s-cwn6wc, 700)";
export var fontWeightHeadingXs = "var(--font-weight-heading-xs-l7rqme, 700)";
export var fontWeightHeavy = "var(--font-weight-heavy-cqd9pv, 700)";
export var letterSpacingBodyS = "var(--letter-spacing-body-s-z9jkwp, 0.005em)";
export var letterSpacingDisplayL = "var(--letter-spacing-display-l-9cigop, -0.03em)";
export var letterSpacingHeadingXl = "var(--letter-spacing-heading-xl-sagq9c, -0.02em)";
export var letterSpacingHeadingL = "var(--letter-spacing-heading-l-qqjd4y, -0.015em)";
export var letterSpacingHeadingM = "var(--letter-spacing-heading-m-0w575i, -0.010em)";
export var letterSpacingHeadingS = "var(--letter-spacing-heading-s-jr3mor, -0.005em)";
export var lineHeightBodyM = "var(--line-height-body-m-30ar75, 20px)";
export var lineHeightBodyS = "var(--line-height-body-s-7zv1j5, 16px)";
export var lineHeightDisplayL = "var(--line-height-display-l-8nioft, 48px)";
export var lineHeightHeadingXl = "var(--line-height-heading-xl-avbttk, 30px)";
export var lineHeightHeadingL = "var(--line-height-heading-l-mmm3my, 24px)";
export var lineHeightHeadingM = "var(--line-height-heading-m-50evfk, 22px)";
export var lineHeightHeadingS = "var(--line-height-heading-s-4i6ewn, 20px)";
export var lineHeightHeadingXs = "var(--line-height-heading-xs-aeleja, 18px)";
export var borderActiveWidth = "var(--border-active-width-9dmqf4, 4px)";
export var borderCodeEditorStatusDividerWidth = "var(--border-code-editor-status-divider-width-tf47q3, 1px)";
export var borderContainerStickyWidth = "var(--border-container-sticky-width-6asz33, 0px)";
export var borderContainerTopWidth = "var(--border-container-top-width-uugwwl, 0px)";
export var borderControlFocusRingShadowSpread = "var(--border-control-focus-ring-shadow-spread-0ctpjf, 0px)";
export var borderControlInvalidFocusRingShadowSpread = "var(--border-control-invalid-focus-ring-shadow-spread-fhmbjn, 2px)";
export var borderDividerListWidth = "var(--border-divider-list-width-27y3k5, 1px)";
export var borderDividerSectionWidth = "var(--border-divider-section-width-1061zr, 1px)";
export var borderDropdownVirtualOffsetWidth = "var(--border-dropdown-virtual-offset-width-kekgc0, 2px)";
export var borderInvalidWidth = "var(--border-invalid-width-5ktjp2, 8px)";
export var borderItemWidth = "var(--border-item-width-yel47s, 2px)";
export var borderLineChartDashArray = "var(--border-line-chart-dash-array-k49cgz, 3 5)";
export var borderLineChartLineJoin = "var(--border-line-chart-line-join-hdz0jf, round)";
export var borderLineChartWidth = "var(--border-line-chart-width-9mzos2, 2px)";
export var borderPanelHeaderWidth = "var(--border-panel-header-width-3w1py7, 1px)";
export var borderPanelTopWidth = "var(--border-panel-top-width-7uc0w9, 1px)";
export var borderRadiusAlert = "var(--border-radius-alert-mdtrse, 12px)";
export var borderRadiusBadge = "var(--border-radius-badge-0z09dt, 4px)";
export var borderRadiusButton = "var(--border-radius-button-ypmfry, 20px)";
export var borderRadiusCalendarDayFocusRing = "var(--border-radius-calendar-day-focus-ring-545h0w, 3px)";
export var borderRadiusCodeEditor = "var(--border-radius-code-editor-hu2cft, 5px)";
export var borderRadiusContainer = "var(--border-radius-container-thqibo, 10px)";
export var borderRadiusControlCircularFocusRing = "var(--border-radius-control-circular-focus-ring-w07mub, 4px)";
export var borderRadiusControlDefaultFocusRing = "var(--border-radius-control-default-focus-ring-u8zbsz, 4px)";
export var borderRadiusDropdown = "var(--border-radius-dropdown-0dhh79, 8px)";
export var borderRadiusDropzone = "var(--border-radius-dropzone-4sx861, 12px)";
export var borderRadiusFlashbar = "var(--border-radius-flashbar-hcdl9q, 12px)";
export var borderRadiusItem = "var(--border-radius-item-05df9h, 8px)";
export var borderRadiusInput = "var(--border-radius-input-rzg4nx, 5px)";
export var borderRadiusPopover = "var(--border-radius-popover-x6vvtk, 5px)";
export var borderRadiusTabsFocusRing = "var(--border-radius-tabs-focus-ring-1q0rjo, 20px)";
export var borderRadiusTiles = "var(--border-radius-tiles-ddqd1q, 5px)";
export var borderRadiusToken = "var(--border-radius-token-7hj3bb, 5px)";
export var borderRadiusChatBubble = "var(--border-radius-chat-bubble-qvkt36, 8px)";
export var borderRadiusTutorialPanelItem = "var(--border-radius-tutorial-panel-item-kydjvp, 5px)";
export var borderTableStickyWidth = "var(--border-table-sticky-width-87kf12, 1px)";
export var borderLinkFocusRingOutline = "var(--border-link-focus-ring-outline-kkfop6, 0)";
export var borderLinkFocusRingShadowSpread = "var(--border-link-focus-ring-shadow-spread-v8hkrl, 2px)";
export var borderWidthAlert = "var(--border-width-alert-t0f3yn, 2px)";
export var borderWidthField = "var(--border-width-field-h1g7tw, 2px)";
export var borderWidthPopover = "var(--border-width-popover-h2a419, 2px)";
export var motionDurationExtraFast = "var(--motion-duration-extra-fast-3huyux, 45ms)";
export var motionDurationExtraSlow = "var(--motion-duration-extra-slow-ngjb9g, 270ms)";
export var motionDurationFast = "var(--motion-duration-fast-sbm3r7, 90ms)";
export var motionDurationModerate = "var(--motion-duration-moderate-5k1phv, 135ms)";
export var motionDurationRefreshOnlyAmbient = "var(--motion-duration-refresh-only-ambient-hnjwnd, 2000ms)";
export var motionDurationRefreshOnlyFast = "var(--motion-duration-refresh-only-fast-4jn2d7, 115ms)";
export var motionDurationRefreshOnlyMedium = "var(--motion-duration-refresh-only-medium-nf6485, 165ms)";
export var motionDurationRefreshOnlySlow = "var(--motion-duration-refresh-only-slow-zbvbxt, 250ms)";
export var motionDurationAvatarGenAiGradient = "var(--motion-duration-avatar-gen-ai-gradient-xetl2o, 3600ms)";
export var motionDurationAvatarLoadingDots = "var(--motion-duration-avatar-loading-dots-pe8a3q, 1200ms)";
export var motionDurationRotate180 = "var(--motion-duration-rotate-180-dpvl4m, 135ms)";
export var motionDurationRotate90 = "var(--motion-duration-rotate-90-4ry26y, 135ms)";
export var motionDurationShowPaced = "var(--motion-duration-show-paced-q2lo2h, 180ms)";
export var motionDurationShowQuick = "var(--motion-duration-show-quick-aq3ump, 135ms)";
export var motionDurationSlow = "var(--motion-duration-slow-z2kaah, 180ms)";
export var motionDurationTransitionQuick = "var(--motion-duration-transition-quick-6npj01, 90ms)";
export var motionDurationTransitionShowPaced = "var(--motion-duration-transition-show-paced-9s9l5z, 180ms)";
export var motionDurationTransitionShowQuick = "var(--motion-duration-transition-show-quick-osg026, 90ms)";
export var motionEasingEaseOutQuart = "var(--motion-easing-ease-out-quart-rrej5m, cubic-bezier(0.165, 0.84, 0.44, 1))";
export var motionEasingRefreshOnlyA = "var(--motion-easing-refresh-only-a-8flf9q, cubic-bezier(0, 0, 0, 1))";
export var motionEasingRefreshOnlyB = "var(--motion-easing-refresh-only-b-ufhmjm, cubic-bezier(1, 0, 0.83, 1))";
export var motionEasingRefreshOnlyC = "var(--motion-easing-refresh-only-c-vg1m9h, cubic-bezier(0.84, 0, 0.16, 1))";
export var motionEasingRefreshOnlyD = "var(--motion-easing-refresh-only-d-3kjugn, cubic-bezier(0.33, 0, 0.67, 1))";
export var motionEasingAvatarGenAiGradient = "var(--motion-easing-avatar-gen-ai-gradient-tjyrpp, cubic-bezier(0.7, 0, 0.3, 1))";
export var motionEasingRotate180 = "var(--motion-easing-rotate-180-e270ko, cubic-bezier(0.165, 0.84, 0.44, 1))";
export var motionEasingRotate90 = "var(--motion-easing-rotate-90-sb4dk7, cubic-bezier(0.165, 0.84, 0.44, 1))";
export var motionEasingShowPaced = "var(--motion-easing-show-paced-ne2o0s, ease-out)";
export var motionEasingShowQuick = "var(--motion-easing-show-quick-vc5zgd, ease-out)";
export var motionEasingTransitionQuick = "var(--motion-easing-transition-quick-l9jfsx, linear)";
export var motionEasingTransitionShowPaced = "var(--motion-easing-transition-show-paced-eobwvi, ease-out)";
export var motionEasingTransitionShowQuick = "var(--motion-easing-transition-show-quick-urd1sm, linear)";
export var motionEasingResponsive = "var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1))";
export var motionEasingSticky = "var(--motion-easing-sticky-56u8cd, cubic-bezier(1, 0, 0.83, 1))";
export var motionEasingExpressive = "var(--motion-easing-expressive-jz8pes, cubic-bezier(0.84, 0, 0.16, 1))";
export var motionDurationResponsive = "var(--motion-duration-responsive-67fgfp, 115ms)";
export var motionDurationExpressive = "var(--motion-duration-expressive-0by09d, 165ms)";
export var motionDurationComplex = "var(--motion-duration-complex-vy4sma, 250ms)";
export var motionKeyframesFadeIn = "var(--motion-keyframes-fade-in-gpabjb, awsui-fade-in-35003c)";
export var motionKeyframesFadeOut = "var(--motion-keyframes-fade-out-kva0ei, awsui-fade-out-35003c)";
export var motionKeyframesStatusIconError = "var(--motion-keyframes-status-icon-error-ldv006, awsui-status-icon-error-35003c)";
export var motionKeyframesScalePopup = "var(--motion-keyframes-scale-popup-d42fd1, awsui-scale-popup-35003c)";
export var sizeCalendarGridWidth = "var(--size-calendar-grid-width-lrunz7, 238px)";
export var sizeControl = "var(--size-control-28i7hr, 16px)";
export var sizeIconBig = "var(--size-icon-big-zkdryd, 32px)";
export var sizeIconLarge = "var(--size-icon-large-bivhmh, 48px)";
export var sizeIconMedium = "var(--size-icon-medium-y9xuoq, 20px)";
export var sizeIconNormal = "var(--size-icon-normal-0m1722, 16px)";
export var sizeTableSelectionHorizontal = "var(--size-table-selection-horizontal-oqvl1b, 40px)";
export var sizeVerticalInput = "var(--size-vertical-input-v5iwwf, 32px)";
export var sizeVerticalPanelIconOffset = "var(--size-vertical-panel-icon-offset-wy8j08, 15px)";
export var spaceAlertActionLeft = "var(--space-alert-action-left-ea9czk, 12px)";
export var spaceAlertHorizontal = "var(--space-alert-horizontal-eyt1xx, 16px)";
export var spaceAlertMessageRight = "var(--space-alert-message-right-knlb6u, 4px)";
export var spaceAlertVertical = "var(--space-alert-vertical-l6cc71, 8px)";
export var spaceButtonFocusOutlineGutter = "var(--space-button-focus-outline-gutter-cqfd0c, 4px)";
export var spaceButtonHorizontal = "var(--space-button-horizontal-8jxzea, 20px)";
export var spaceButtonIconFocusOutlineGutterVertical = "var(--space-button-icon-focus-outline-gutter-vertical-zgqy8m, 0px)";
export var spaceButtonIconOnlyHorizontal = "var(--space-button-icon-only-horizontal-zsf2nw, 6px)";
export var spaceButtonInlineIconFocusOutlineGutter = "var(--space-button-inline-icon-focus-outline-gutter-vk4r2v, 0px)";
export var spaceButtonModalDismissVertical = "var(--space-button-modal-dismiss-vertical-0n4y5m, 2px)";
export var spaceCalendarGridFocusOutlineGutter = "var(--space-calendar-grid-focus-outline-gutter-g00bgj, -5px)";
export var spaceCalendarGridSelectedFocusOutlineGutter = "var(--space-calendar-grid-selected-focus-outline-gutter-hxbct6, -5px)";
export var spaceCalendarGridGutter = "var(--space-calendar-grid-gutter-2jsrps, 6px)";
export var spaceCardHorizontal = "var(--space-card-horizontal-tuwbbd, 20px)";
export var spaceCardVertical = "var(--space-card-vertical-m591he, 16px)";
export var spaceCodeEditorStatusFocusOutlineGutter = "var(--space-code-editor-status-focus-outline-gutter-dqxswe, -7px)";
export var spaceContainerContentTop = "var(--space-container-content-top-1ae0r3, 4px)";
export var spaceContainerHeaderTop = "var(--space-container-header-top-5qav00, 12px)";
export var spaceContainerHeaderBottom = "var(--space-container-header-bottom-vj01hn, 8px)";
export var spaceContainerHorizontal = "var(--space-container-horizontal-wfukh3, 20px)";
export var spaceContentHeaderPaddingBottom = "var(--space-content-header-padding-bottom-y2enua, 16px)";
export var spaceDarkHeaderOverlapDistance = "var(--space-dark-header-overlap-distance-lxpp0s, 36px)";
export var spaceExpandableSectionIconOffsetTop = "var(--space-expandable-section-icon-offset-top-ekk5vc, 4px)";
export var spaceFieldHorizontal = "var(--space-field-horizontal-gg19kw, 12px)";
export var spaceFieldIconOffset = "var(--space-field-icon-offset-csk9vw, 36px)";
export var spaceFilteringTokenDismissButtonFocusOutlineGutter = "var(--space-filtering-token-dismiss-button-focus-outline-gutter-76gsim, -5px)";
export var spaceFilteringTokenOperationSelectFocusOutlineGutter = "var(--space-filtering-token-operation-select-focus-outline-gutter-0ailok, -5px)";
export var spaceFlashbarActionLeft = "var(--space-flashbar-action-left-qkhz5m, 12px)";
export var spaceFlashbarDismissRight = "var(--space-flashbar-dismiss-right-tcpfax, 0px)";
export var spaceFlashbarHorizontal = "var(--space-flashbar-horizontal-xmjla3, 16px)";
export var spaceGridGutter = "var(--space-grid-gutter-ieqn7e, 20px)";
export var spaceKeyValueGap = "var(--space-key-value-gap-0517k5, 0px)";
export var spaceLayoutContentBottom = "var(--space-layout-content-bottom-noqzmw, 40px)";
export var spaceLayoutContentHorizontal = "var(--space-layout-content-horizontal-7l52k3, 24px)";
export var spaceLayoutToggleDiameter = "var(--space-layout-toggle-diameter-hb85rk, 36px)";
export var spaceLayoutTogglePadding = "var(--space-layout-toggle-padding-lcu6qi, 12px)";
export var spaceModalContentBottom = "var(--space-modal-content-bottom-hqahub, 16px)";
export var spaceModalHorizontal = "var(--space-modal-horizontal-f89syl, 20px)";
export var spaceOptionIconBigTop = "var(--space-option-icon-big-top-hybljv, 0px)";
export var spacePanelContentBottom = "var(--space-panel-content-bottom-7k6er3, 40px)";
export var spacePanelContentTop = "var(--space-panel-content-top-wac3yp, 20px)";
export var spacePanelDividerMarginHorizontal = "var(--space-panel-divider-margin-horizontal-sbik1z, 8px)";
export var spacePanelHeaderVertical = "var(--space-panel-header-vertical-gv9pti, 20px)";
export var spacePanelNavLeft = "var(--space-panel-nav-left-b1g0mt, 28px)";
export var spacePanelSideLeft = "var(--space-panel-side-left-5t0who, 28px)";
export var spacePanelSideRight = "var(--space-panel-side-right-xofg51, 24px)";
export var spacePanelSplitTop = "var(--space-panel-split-top-q1pux4, 20px)";
export var spacePanelSplitBottom = "var(--space-panel-split-bottom-qwdduf, 20px)";
export var spaceSegmentedControlFocusOutlineGutter = "var(--space-segmented-control-focus-outline-gutter-gbye20, 4px)";
export var spaceTabsContentTop = "var(--space-tabs-content-top-dedu0k, 12px)";
export var spaceTabsFocusOutlineGutter = "var(--space-tabs-focus-outline-gutter-xm37ly, -8px)";
export var spaceTableContentBottom = "var(--space-table-content-bottom-9yr834, 4px)";
export var spaceTableEmbeddedHeaderTop = "var(--space-table-embedded-header-top-bfjeam, 0px)";
export var spaceTableFooterHorizontal = "var(--space-table-footer-horizontal-ptapb9, 0px)";
export var spaceTableHeaderFocusOutlineGutter = "var(--space-table-header-focus-outline-gutter-7js4en, 0px)";
export var spaceTableHeaderHorizontal = "var(--space-table-header-horizontal-wl3ykr, 0px)";
export var spaceTableHeaderToolsBottom = "var(--space-table-header-tools-bottom-25ubsj, 0px)";
export var spaceTableHeaderToolsFullPageBottom = "var(--space-table-header-tools-full-page-bottom-xulfq9, 4px)";
export var spaceTableHorizontal = "var(--space-table-horizontal-8davlm, 20px)";
export var spaceTileGutter = "var(--space-tile-gutter-fuuodu, 24px)";
export var spaceScaled2xNone = "var(--space-scaled-2x-none-8i5al4, 0px)";
export var spaceScaled2xXxxs = "var(--space-scaled-2x-xxxs-fp07j9, 2px)";
export var spaceScaled2xXxs = "var(--space-scaled-2x-xxs-7v8ivz, 4px)";
export var spaceScaled2xXs = "var(--space-scaled-2x-xs-cijznx, 8px)";
export var spaceScaled2xS = "var(--space-scaled-2x-s-owsac7, 12px)";
export var spaceScaled2xM = "var(--space-scaled-2x-m-owtj1o, 16px)";
export var spaceScaled2xL = "var(--space-scaled-2x-l-ujnz41, 20px)";
export var spaceScaled2xXl = "var(--space-scaled-2x-xl-udij7m, 24px)";
export var spaceScaled2xXxl = "var(--space-scaled-2x-xxl-ts4prk, 32px)";
export var spaceScaled2xXxxl = "var(--space-scaled-2x-xxxl-eqb3m6, 40px)";
export var spaceScaledNone = "var(--space-scaled-none-vhzhxe, 0px)";
export var spaceScaledXxxs = "var(--space-scaled-xxxs-27y4hv, 2px)";
export var spaceScaledXxs = "var(--space-scaled-xxs-7597g1, 4px)";
export var spaceScaledXs = "var(--space-scaled-xs-26e2du, 8px)";
export var spaceScaledS = "var(--space-scaled-s-aqzyko, 12px)";
export var spaceScaledM = "var(--space-scaled-m-mo5yse, 16px)";
export var spaceScaledL = "var(--space-scaled-l-0hpmd7, 20px)";
export var spaceScaledXl = "var(--space-scaled-xl-kswcw7, 24px)";
export var spaceScaledXxl = "var(--space-scaled-xxl-wbot5q, 32px)";
export var spaceScaledXxxl = "var(--space-scaled-xxxl-fdg8ai, 40px)";
export var spaceStaticXxxs = "var(--space-static-xxxs-3gu9os, 2px)";
export var spaceStaticXxs = "var(--space-static-xxs-82cdfi, 4px)";
export var spaceStaticXs = "var(--space-static-xs-7sfb63, 8px)";
export var spaceStaticS = "var(--space-static-s-n2eb28, 12px)";
export var spaceStaticM = "var(--space-static-m-lrhgl2, 16px)";
export var spaceStaticL = "var(--space-static-l-0xsx8r, 20px)";
export var spaceStaticXl = "var(--space-static-xl-qalw6z, 24px)";
export var spaceStaticXxl = "var(--space-static-xxl-02zgl2, 32px)";
export var spaceStaticXxxl = "var(--space-static-xxxl-qj5g91, 40px)";
export var spaceNone = "var(--space-none-zrljql, 0px)";
export var spaceXxxs = "var(--space-xxxs-zbmxqb, 2px)";
export var spaceXxs = "var(--space-xxs-p8yyaw, 4px)";
export var spaceXs = "var(--space-xs-zb16t3, 8px)";
export var spaceS = "var(--space-s-34lx8l, 12px)";
export var spaceM = "var(--space-m-udix3p, 16px)";
export var spaceL = "var(--space-l-t419sm, 20px)";
export var spaceXl = "var(--space-xl-lmui9r, 24px)";
export var spaceXxl = "var(--space-xxl-cu2m1r, 32px)";
export var spaceXxxl = "var(--space-xxxl-4x2gki, 40px)";
export var shadowContainer = "var(--shadow-container-fxmdn3, 0px 0px 1px 1px #e9ebed, 0px 1px 8px 2px rgba(0, 7, 22, 0.12))";
export var shadowContainerStacked = "var(--shadow-container-stacked-4gw245, -1px 1px 1px 0px #e9ebed, 1px 1px 1px 0px #e9ebed, 0px 9px 8px -7px rgb(0 7 22 / 12%), 8px 0px 8px -7px rgb(0 7 22 / 12%), -8px 0px 8px -7px rgb(0 7 22 / 12%))";
export var shadowContainerActive = "var(--shadow-container-active-kl29x9, 0px 1px 1px 1px #e9ebed, 0px 6px 36px #0007161a)";
export var shadowDropdown = "var(--shadow-dropdown-e5guvm, 0px 4px 20px 1px rgba(0, 7, 22, 0.10))";
export var shadowDropup = "var(--shadow-dropup-xqkiza, 0px 4px 20px 1px rgba(0, 7, 22, 0.10))";
export var shadowFlashCollapsed = "var(--shadow-flash-collapsed-s7gipk, 0px 4px 4px rgba(0, 0, 0, 0.25))";
export var shadowFlashSticky = "var(--shadow-flash-sticky-pq8cuq, 0px 4px 8px rgba(0, 7, 22, 0.10))";
export var shadowModal = "var(--shadow-modal-2ennni, 0px 4px 20px 1px rgba(0, 7, 22, 0.10))";
export var shadowPanel = "var(--shadow-panel-g6fkp1, 0px 0px 0px 1px #b6bec9)";
export var shadowPanelToggle = "var(--shadow-panel-toggle-0l64f2, 0px 6px 12px 1px rgba(0, 7, 22, 0.12))";
export var shadowPopover = "var(--shadow-popover-8zls8c, 0px 4px 20px 1px rgba(0, 7, 22, 0.10))";
export var shadowSplitBottom = "var(--shadow-split-bottom-5e389u, 0px -36px 36px -36px rgba(0, 7, 22, 0.10))";
export var shadowSplitSide = "var(--shadow-split-side-ggdtnk, -1px 0px 1px 0px #e9ebed, -36px 6px 36px -36px rgba(0, 7, 22, 0.10))";
export var shadowSticky = "var(--shadow-sticky-blixp9, 0px 4px 8px 1px rgba(0, 7, 22, 0.10))";
export var shadowStickyEmbedded = "var(--shadow-sticky-embedded-uvh5ry, 0px 2px 0px 0px #e9ebed, 0px 16px 16px -12px rgba(0, 7, 22, 0.10))";
export var shadowStickyColumnFirst = "var(--shadow-sticky-column-first-039dqs, 4px 0px 8px 1px rgba(0, 7, 22, 0.1))";
export var shadowStickyColumnLast = "var(--shadow-sticky-column-last-n170vw, -4px 0 8px 1px rgba(0, 28, 36, 0.1))";