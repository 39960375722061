
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_14iqq_11fry_189",
  "fit-height": "awsui_fit-height_14iqq_11fry_222",
  "with-side-media": "awsui_with-side-media_14iqq_11fry_227",
  "variant-default": "awsui_variant-default_14iqq_11fry_230",
  "variant-stacked": "awsui_variant-stacked_14iqq_11fry_230",
  "refresh": "awsui_refresh_14iqq_11fry_238",
  "sticky-enabled": "awsui_sticky-enabled_14iqq_11fry_288",
  "with-top-media": "awsui_with-top-media_14iqq_11fry_300",
  "content-wrapper": "awsui_content-wrapper_14iqq_11fry_305",
  "content-wrapper-fit-height": "awsui_content-wrapper-fit-height_14iqq_11fry_310",
  "media": "awsui_media_14iqq_11fry_317",
  "media-top": "awsui_media-top_14iqq_11fry_335",
  "media-side": "awsui_media-side_14iqq_11fry_340",
  "header": "awsui_header_14iqq_11fry_346",
  "header-full-page": "awsui_header-full-page_14iqq_11fry_351",
  "header-with-media": "awsui_header-with-media_14iqq_11fry_354",
  "header-sticky-disabled": "awsui_header-sticky-disabled_14iqq_11fry_360",
  "header-sticky-enabled": "awsui_header-sticky-enabled_14iqq_11fry_364",
  "header-stuck": "awsui_header-stuck_14iqq_11fry_370",
  "header-variant-cards": "awsui_header-variant-cards_14iqq_11fry_380",
  "header-dynamic-height": "awsui_header-dynamic-height_14iqq_11fry_383",
  "with-paddings": "awsui_with-paddings_14iqq_11fry_389",
  "with-hidden-content": "awsui_with-hidden-content_14iqq_11fry_398",
  "header-variant-full-page": "awsui_header-variant-full-page_14iqq_11fry_456",
  "header-cover": "awsui_header-cover_14iqq_11fry_459",
  "content": "awsui_content_14iqq_11fry_305",
  "content-fit-height": "awsui_content-fit-height_14iqq_11fry_487",
  "content-inner": "awsui_content-inner_14iqq_11fry_493",
  "with-header": "awsui_with-header_14iqq_11fry_500",
  "footer": "awsui_footer_14iqq_11fry_504",
  "with-divider": "awsui_with-divider_14iqq_11fry_508"
};
  